<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <path
      style="fill:#F64B4A;"
      d="M256.001,512c-141.158,0-256-114.841-256-256s114.842-256,256-256s255.999,114.841,255.999,256
	S397.16,512,256.001,512z"
    />
    <path
      style="fill:#FED159;"
      d="M256.001,459.333c-112.117,0-203.333-91.215-203.333-203.333S143.883,52.667,256.001,52.667
	S459.332,143.883,459.332,256S368.118,459.333,256.001,459.333z"
    />
    <path
      style="fill:#F64B4A;"
      d="M256.001,406.665c-83.076,0-150.665-67.589-150.665-150.665s67.589-150.665,150.665-150.665
	S406.665,172.924,406.665,256S339.078,406.665,256.001,406.665z"
    />
    <circle style="fill:#FED159;" cx="255.999" cy="255.999" r="97.997" />
    <polygon
      style="fill:#F64B4A;"
      points="247.223,230.706 264.779,230.706 292.874,230.706 292.874,213.15 264.779,213.15 
	264.779,193.679 247.223,193.679 247.223,213.15 219.129,213.15 219.129,264.778 275.318,264.778 275.318,281.294 264.779,281.294 
	247.223,281.294 219.129,281.294 219.129,298.85 247.223,298.85 247.223,318.321 264.779,318.321 264.779,298.85 292.874,298.85 
	292.874,247.222 236.685,247.222 236.685,230.706 "
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
